import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import lineDistance from "@turf/line-distance";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitterSquare,
  faFacebook,
  faTumblrSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faInfo, faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import "mapbox-gl/dist/mapbox-gl.css";
import "./style.scss";
import plaatsen from "./plaatsen";

window.onload = function () {
  const message = document.querySelector("#welcome");
  const canvas = document.createElement("canvas");
  // Get WebGLRenderingContext from canvas element.
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
  } else {
    message.innerHTML =
      "<h1>Het Savelsbos</h1> <br> Je browser is te oud en ondersteunt geen WebGL. Update je browser om de kaart te zien.";
  }

  setTimeout(function () {
    const koopDan = document.createElement("div");
    koopDan.className = "popup";
    koopDan.id = "koopDan";

    koopDan.innerHTML = `<span class="close" onclick="this.parentNode.remove()";>&times;</span> <strong>Steun deze website door <a href="/koopkaart.html" target="_blank">een papieren kaart van het Savelsbos te kopen</a>. Deze kaart is gedrukt op stevig extra dik A3 papier en bevat de beschrijvingen op de achterkant.</strong>`;

    document.body.append(koopDan);
  }, 515000);

  const welcome = document.getElementById("welcome");
  const modal = document.getElementById("modal");

  const hidesWelcome = document.querySelectorAll(".hideWelcome");
  hidesWelcome.forEach((element) => {
    element.addEventListener("click", () => {
      welcome.style =
        "visibility: hidden; opacity: 0;transition: visibility 0s linear 0.15s, opacity 0.15s linear;";
      modal.style = "display: none";
    });
  });

  const infoIcon = document.getElementById("infoIcon");
  infoIcon.onclick = (event) => {
    welcome.style = "visibility: visible; opacity: 1; transition-delay: 0s;";
    modal.style = "display: block";
  };
};

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

// Set bounds
const bounds = [
  [5.669, 50.747033], // Southwest coordinates
  [5.860251, 50.8403], // Northeast coordinates
];

const map = new mapboxgl.Map({
  container: "map",
  style: "mapbox://styles/wompo/cix4t043i006t2pnwfrmgteah",
  center: [5.761263, 50.810431],
  zoom: 13.4,
  maxBounds: bounds,
});

// Add zoom and rotation controls to the map.
map.addControl(new mapboxgl.NavigationControl());

// Add geolocate control to the map.
map.addControl(
  new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
  }),
);



// Use promise to load GeoJSON
map.on("load", function () {
  // Add GeoJSON source containing place coordinates and information.
  map.addSource("places", plaatsen);

  map.addSource("dem", {
    type: "raster-dem",
    url: "mapbox://mapbox.terrain-rgb",
  });
  map.addLayer(
    {
      id: "hillshading",
      source: "dem",
      type: "hillshade",
      "hillshade-exaggeration": 0.7,
      // insert below waterway-river-canal-shadow;
      // where hillshading sits in the Mapbox Outdoors style
    },
    "waterway-river-canal-shadow",
  );

  // Add a layer showing the places.
  map.addLayer({
    id: "polygons",
    type: "fill",
    source: "places",
    paint: {
      "fill-color": "red",
      "fill-opacity": 1,
    },
    filter: ["==", "$type", "Polygon"],
  });

  map.addLayer({
    id: "places",
    type: "symbol",
    source: "places",
    layout: {
      "icon-image": "{icon}-15",
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "text-ignore-placement": true,
      "text-allow-overlap": true,
    },
    filter: ["==", "$type", "Point"],
  });
});

// When a click event occurs near a place, open a popup at the location of the feature, with description HTML from its properties.
map.on("click", function (e) {
  const features = map.queryRenderedFeatures(e.point, { layers: ["places"] });

  if (!features.length) return;

  const feature = features[0];

  // Populate the popup and set its coordinates based on the feature found.
  const popup = new mapboxgl.Popup({ maxWidth: "450px" })
    .setLngLat(feature.geometry.coordinates)
    .setHTML(feature.properties.description)
    .addTo(map);
});

const routeButtons = Array.from(document.querySelectorAll(".route")); // element array for routes

let route = null;
let clickedSame = false; // Keep track of wether the same is clicked

function addRoute() {
  if (this.dataset.name === route) {
    // If same route is clicked again, remove layer from map and set route to null again.
    clickedSame = !clickedSame;
    removeRoute(route);
    route = null;
  } else {
    const routeElement = this.querySelector("span");

    // If there is a previous route, remove it
    if (route) removeRoute(route);

    route = this.dataset.name;

    fetch(`routes/${route}.json`)
      .then(function (response) {
        return response.json();
      })
      .then(function (myBlob) {
        const length = lineDistance(myBlob).toPrecision(2);
        routeElement.innerHTML = `${length} km`;

        map.addLayer({
          id: route,
          type: "line",
          source: {
            type: "geojson",
            data: myBlob,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#fea57f",
            "line-width": 8,
            "line-opacity": 0.7,
          },
        });
      });
  }
}

function removeRoute(id) {
  map.removeLayer(id);
  map.removeSource(id);
}

routeButtons.forEach((key) => key.addEventListener("click", addRoute));

// Use the same approach as above to indicate that the symbols are clickable by changing the cursor style to "pointer".
// Listen to render then check wether map is loaded then change pointer onmousemove
map.on("render", function () {
  if (map.loaded()) {
    map.on("mousemove", function (e) {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["places"],
      });
      map.getCanvas().style.cursor = features.length ? "pointer" : "";
    });
  }
});

library.add(
  faInfo,
  faEnvelopeSquare,
  faFacebook,
  faTwitterSquare,
  faTumblrSquare,
);
dom.watch();
